import React from "react";
import classNames from "classnames";

import Link from "./Link";

export default function SiteLogo({ locale, className, style }) {
  return (
    <Link to={`/${locale}`} alt="">
      <svg
        className={classNames('text-logo-black dark:text-white', className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 202.64 149"
        style={style}
      >
        <g fill="currentColor">
          <path d="M134.52,145.37a2.11,2.11,0,0,0,0-.26,3,3,0,0,0,.42,0,1,1,0,0,0,1.07-.93.67.67,0,0,0,0-.2c0-.71-.34-1.07-1-1.07a1,1,0,0,0-1,.93l-.07,0a4.16,4.16,0,0,0-.35-.7,2.36,2.36,0,0,1,1.58-.58c1,0,1.61.53,1.61,1.29s-.47,1.18-1.38,1.43c1.09.06,1.65.54,1.65,1.39,0,1-.83,1.78-2.14,1.78a3,3,0,0,1-1.47-.33,9,9,0,0,0,.16-1h.09a1.18,1.18,0,0,0,1.21,1,1.25,1.25,0,0,0,1.28-1.2,1.09,1.09,0,0,0,0-.18,1.11,1.11,0,0,0-1-1.24,1,1,0,0,0-.25,0,2.44,2.44,0,0,0-.46.07.86.86,0,0,0,0-.23" />
          <path d="M124.4,147.08a1.08,1.08,0,0,0,1.09,1.06h.09a1.23,1.23,0,0,0,1.33-1.39,1.22,1.22,0,0,0-1.06-1.35h-.25a1.23,1.23,0,0,0-.86.33l-.13-.08a5.93,5.93,0,0,0,.06-.84v-.22a16,16,0,0,0-.07-1.83c.73,0,1.23.06,1.49.06.54,0,1.06,0,1.55-.06a2.59,2.59,0,0,0,0,.48c0,.11,0,.22,0,.32h-2.53q-.06.79-.06,1.65a2.27,2.27,0,0,1,1-.21,1.58,1.58,0,0,1,1.76,1.37,1.93,1.93,0,0,1,0,.24c0,1.13-.84,1.89-2.22,1.89a2.69,2.69,0,0,1-1.44-.34c.07-.45.12-.8.16-1.06Z" />
          <path d="M116,148.13c1,0,1.72-1,1.78-2.62a1.89,1.89,0,0,1-1.4.61,1.58,1.58,0,0,1-1.68-1.45,2.81,2.81,0,0,1,0-.29,1.8,1.8,0,0,1,1.81-1.78h.11c1.38,0,2.05.82,2.05,2.46,0,2.1-1.12,3.43-2.76,3.43a2.06,2.06,0,0,1-.78-.12l-.08-.58a1.45,1.45,0,0,0,.94.34m-.45-3.76c0,.84.44,1.33,1.11,1.33s1.13-.47,1.13-1.31-.39-1.44-1.07-1.44-1.17.51-1.17,1.42" />
          <path d="M107.13,144.39c0-.31,0-.65,0-1l-.43.23a7,7,0,0,0-.81.52,1.21,1.21,0,0,0-.4-.44,10,10,0,0,0,2.45-1.2l.1,0c-.07,1.28-.1,2.72-.1,4.32,0,.48,0,1,0,1.57a1.84,1.84,0,0,0-.47-.06,1.76,1.76,0,0,0-.46.06c.08-.83.14-2.15.14-4" />
          <path d="M97.77,148H94.29v.6c0,.11-.08.21-.25.3a.92.92,0,0,1-.39.14c-.08,0-.12-.05-.12-.14l0-1v-4.46l-.06-1.25,1,.43H95a7.59,7.59,0,0,0,.43-1.62c.66.24,1,.4,1,.47s-.06.12-.17.18a1.19,1.19,0,0,0-.35.32l-.5.65H97.5l.45-.4a.16.16,0,0,1,.09,0,1,1,0,0,1,.4.24,1.41,1.41,0,0,1,.33.28s0,.08-.11.16a.59.59,0,0,0-.13.18.88.88,0,0,0,0,.28v4.16l0,1.11c0,.06-.1.12-.3.2a1.45,1.45,0,0,1-.42.12s-.08,0-.08-.07Zm0-5.13H94.29v1.36h3.48Zm0,1.61H94.29v1.42h3.48Zm0,1.68H94.29v1.58h3.48Z" />
          <path d="M79,148.43l-.2-.33H83a20.55,20.55,0,0,0,1-4.45,9.3,9.3,0,0,1,1,.47c.13.09.19.16.19.21s-.06.12-.19.15-.2.07-.21.11l-.78,2-.62,1.46H85.2l.52-.69.1,0a1.76,1.76,0,0,1,.53.41c.3.27.44.43.44.48s0,.08-.06.08H80.27c-.16,0-.57,0-1.23.08m.47-4.77-.22-.37H85a4.1,4.1,0,0,1,.6-.72,2.71,2.71,0,0,1,.52.43c.31.29.47.46.47.53s0,.05-.07.05H80.37c-.15,0-.44,0-.86.08m1.47.07a6.12,6.12,0,0,1,1.4,2.88,1.15,1.15,0,0,1-.22.71.61.61,0,0,1-.44.3c-.12,0-.2-.1-.23-.3a8.86,8.86,0,0,0-.67-3.52.3.3,0,0,1,.16-.08m.9-2.52.1-.12a4.53,4.53,0,0,1,1,.44,1.44,1.44,0,0,1,.53.45.91.91,0,0,1,.1.4.89.89,0,0,1-.18.5.55.55,0,0,1-.39.23c-.14,0-.24-.12-.3-.37a3.05,3.05,0,0,0-.84-1.53" />
          <path d="M70.44,143h1.7l.33-.53s0,0,.07,0a1.47,1.47,0,0,1,.4.29l.33.29c.09.08.13.13.13.17s0,.07-.06.07H70.48A9.83,9.83,0,0,0,71,146h0a7.4,7.4,0,0,0,.75-2.24c.66.26,1,.43,1,.52s0,.09-.1.11a.75.75,0,0,0-.13.06,2.18,2.18,0,0,0-.22.43,10.33,10.33,0,0,1-1,1.86,2.61,2.61,0,0,0,.69.75,1.43,1.43,0,0,0,.56.29.18.18,0,0,0,.13-.06,2.16,2.16,0,0,0,.22-.49c.12-.29.22-.58.32-.86l.14,0q-.09.67-.12,1.35a2.09,2.09,0,0,0,.26,1.07q0,.21-.15.21a2.65,2.65,0,0,1-1.24-.47,4.09,4.09,0,0,1-1.31-1.21A8.1,8.1,0,0,1,68,148.85l-.1-.11a10.22,10.22,0,0,0,2.58-2,12.15,12.15,0,0,1-.74-3.52H67.5l0,.92v.45h1l.29-.32s0,0,.09,0a1.27,1.27,0,0,1,.36.2q.3.2.3.24s-.08.15-.26.31l-.1,1.19a5.08,5.08,0,0,1-.15.95,1.17,1.17,0,0,1-.3.4,1.32,1.32,0,0,1-.52.28l-.25.08a.78.78,0,0,0-.2-.43.7.7,0,0,0-.28-.23l-.28-.12,0-.16.92.09a.16.16,0,0,0,.16-.07,5.73,5.73,0,0,0,.14-.91,10.2,10.2,0,0,0,.1-1.23H67.44l-.11,1.32a3.72,3.72,0,0,1-.36,1.23,5.17,5.17,0,0,1-1.3,1.53.47.47,0,0,1-.12-.07,8.73,8.73,0,0,0,.74-1.3,4.23,4.23,0,0,0,.31-1.26c.05-.48.07-.8.07-1a.06.06,0,0,0,0-.06l0,0V143.8c0-.06,0-.1,0-.1q0-.25-.06-1.17a3,3,0,0,1,.58.24l.37.19h2.17l-.12-1.52-.06-.42q1.14.21,1.14.33s0,.09-.13.14a.42.42,0,0,0-.16.11.28.28,0,0,0,0,.13c0,.2,0,.61.07,1.22m.31-1.36.06-.14q1.38.17,1.38.78a.66.66,0,0,1-.17.4.47.47,0,0,1-.31.2.18.18,0,0,1-.16-.1,2.64,2.64,0,0,0-.8-1.15" />
          <rect x="29.52" y="107.28" width="0.55" height="23.84" />
          <rect x="65.28" y="107.28" width="0.55" height="23.84" />
          <rect x="101.04" y="107.28" width="0.55" height="23.84" />
          <rect x="136.79" y="107.28" width="0.55" height="23.84" />
          <rect x="172.55" y="107.28" width="0.55" height="23.84" />
          <path d="M199.82,109.63h-1.43v-2.12l-3.86,0v2.13h-1.08v-.46h-3.86v3.59h-.18c-.26,0-.46-.17-.59-.53a14,14,0,0,1-.88-2.91,2.17,2.17,0,0,0-2.26-1.41,5.92,5.92,0,0,0-1.86.33l-.32.11.25-1-3.25,0c0,.17-.06.44-.06.44a10.27,10.27,0,0,1-2.14,6l.11.1a9.09,9.09,0,0,0,3.77-2.7,6.35,6.35,0,0,0,1.09-2l0-.1h.08l.24,0c.59,0,1,.48,1.13,1.42l.36,1.36,0,.18-.18,0s-2.87,0-3.13,0v2.18h-2.8V129.1c.16,1.35,1,2,2.51,2a12.54,12.54,0,0,0,4.76-1.25l.2-.09.05.21a1.23,1.23,0,0,0,1.27,1c.74-.06,1.16-.58,1.27-1.57a2.77,2.77,0,0,0-.72-1.75,12.49,12.49,0,0,0-2.57-2.41,2.11,2.11,0,0,1-.15.19,15.25,15.25,0,0,1,.8,3.72v.14l-.14,0a10.26,10.26,0,0,1-2.83.68c-.33,0-.9-.14-.9-1.08v-4.51h6.08v-7.66c-.06-1.56-1.08-2.4-3.12-2.54h-.29v-1.85l.4.39a2.53,2.53,0,0,0,1.85.67,4.29,4.29,0,0,0,2.28-.69l0,0h3.59v-2.61h3.7a1.26,1.26,0,0,1,1.42,1.08,1.08,1.08,0,0,1,0,.31v1.22h4.06v-1c-.08-1.42-1-2.11-2.82-2.11m-14.76,14.29h-2.43v-4.13h2.43Zm-2.43-4.68v-4.42H184a1,1,0,0,1,1.1,1v3.43Zm17,2.86h-5.44v-1.84h7.63v-3.87a2.06,2.06,0,0,0-1.77-2.33,1.79,1.79,0,0,0-.61,0h-5.25v-.51h-3.76v17.09h11.91v-5.9c0-1.77-.88-2.63-2.71-2.63m-5.44-7.5h2.06a1.42,1.42,0,0,1,1.6,1.21,1.09,1.09,0,0,1,0,.36v3.51h-3.66Zm4.2,15.48h-4.2V123h0v-.36h2.38a1.63,1.63,0,0,1,1.81,1.42,1.21,1.21,0,0,1,0,.38Z" />
          <path d="M166.88,111.92a.91.91,0,0,1-.63.42,1.39,1.39,0,0,1-1-.61c-.75-1-1.35-1.87-1.81-2.62a3.34,3.34,0,0,0-2.13-1.42,28.12,28.12,0,0,0-4-.17,32.22,32.22,0,0,0-6.17.82l-.27.06.23-1.09h-4.61a9.29,9.29,0,0,1-3.29,6l.14.18a15.22,15.22,0,0,0,4.32-1.37l0,0h13.62l.64.55a5.24,5.24,0,0,0,3.09.87,2.89,2.89,0,0,0,2.09-1.42l-.22-.17m-18.34-.37.37-.32a5.91,5.91,0,0,0,1.67-2.23l0-.09.09,0a34.83,34.83,0,0,1,5.93-.82,2.79,2.79,0,0,1,2.89,1.55,8.93,8.93,0,0,0,1,1.64l.22.29Zm13.79,11.2H148.62v-.58h-4.07v8.68h20.93v-5.06c0-2.05-1-3-3.14-3m-1,7.56H148.61v-3.1H161.3Zm0-3.64H148.61v-3.38h11.15a1.35,1.35,0,0,1,1.53,1.15,1.37,1.37,0,0,1,0,.35Zm2.08-12.59H147.8v.54h5.11v6.09H148v-4.95h0v-2.14h-4.05v7.65h22.16V116.7c0-1.77-.88-2.63-2.7-2.63m-1.48,6.63h-5v-6.09h3.41a1.43,1.43,0,0,1,1.6,1.22,1.45,1.45,0,0,1,0,.36Zm-4.35-.59h0a5.62,5.62,0,0,0,2.56-1.79,5.51,5.51,0,0,0,1-2.58h-2.42a10.8,10.8,0,0,1-1.19,4.38m-6.87-.17a1.25,1.25,0,0,0,1.25-1.25.31.31,0,0,0,0-.1v-.08a1.35,1.35,0,0,0-.64-1,9.94,9.94,0,0,0-2.42-1.46l0,0a5.72,5.72,0,0,1,.69,2.2c.1,1.68.9,1.68,1.16,1.68" />
          <path d="M107.17,110a2,2,0,0,0,.12.21l4.93-.17v3.23h-4.81l.5.54h4.31v17.06h3.86V113.78h5l-.53-.55h-4.51v-3.36h.17c1.71-.1,3.17-.22,4.42-.37l-2.38-1.94A89.93,89.93,0,0,1,107.17,110m14.64,20.66h9.46V110.45a2.52,2.52,0,0,0-2.14-2.84,2.72,2.72,0,0,0-.49,0,9.36,9.36,0,0,0-2.92.53l-.24.08v-.61H121.8c0,.35,0,22.7,0,23M125.48,109l.14,0a6.55,6.55,0,0,1,1.39-.19.77.77,0,0,1,.69.79v20.55h-2.22Zm-17.16,7.55v3.26c0,2.13-.07,3.94-.19,5.39a13.44,13.44,0,0,1-.73,3.37l.07.06a9.24,9.24,0,0,0,2.35-2.67,12.18,12.18,0,0,0,1.22-6.11v-3.3Zm11.63,0v3.26c0,2.13.07,3.94.19,5.39a13.44,13.44,0,0,0,.73,3.37l-.07.06a9.24,9.24,0,0,1-2.35-2.67c-.81-1.31-1.22-3.37-1.22-6.11v-3.3Z" />
          <path d="M80.57,108.4l.49.54h4.38c1.16,0,1.47.66,1.53,1.16.51,7.74,1.26,12.53,2.56,15.06a12.74,12.74,0,0,0,6,5.5.47.47,0,0,0,.1-.1,23.56,23.56,0,0,1-2.91-7,90.16,90.16,0,0,1-1.59-13.8c-.05-.9-.7-1.33-2-1.33Zm-5,1.61v5.15c0,5.58-1.8,12-4.71,15.4l.13.12a12.46,12.46,0,0,0,6.3-5.7,17.85,17.85,0,0,0,2.57-9.82V110Z" />
          <path d="M35.84,127.51c.13.2,1.81,3,2.1,3.51a18.53,18.53,0,0,0,1.79-5.38,43.75,43.75,0,0,0,.69-6.26l-.3-.15a19.11,19.11,0,0,1-4.28,8.28m2.75-8.28c.91-.06,1.43-.67,1.56-1.86a2.2,2.2,0,0,0-1-1.6,16.46,16.46,0,0,0-3.66-2.27l-.22.29a7,7,0,0,1,1.7,3.36c.1,1.4.62,2.07,1.59,2.07m21.29,1.24,0-.15a7.45,7.45,0,0,1-2.34-2,11.68,11.68,0,0,1-1.13-2l-.12-.26h3.18l-.47-.54h-2.6v-5H59l-.48-.54H56.45v-2.38h-4V110H48v-2.38H43.82V110H41.45l.48.55h1.89v5H41l.48.55h2.71l-.06.23A8,8,0,0,1,40.85,121a.66.66,0,0,1,0,.15,6.35,6.35,0,0,0,2.06-.46l.26-.13v7.93c0,1.52,1,2.25,3.12,2.25h9.58a3.68,3.68,0,0,0,2.39-.63,4.76,4.76,0,0,0,1.19-1.83l-.4-.15A4.5,4.5,0,0,1,58,129.63a2.91,2.91,0,0,1-2.1.56h-7.1c-1.14,0-1.46-.64-1.53-1.17v-3.24H58.22v-3.15a3.12,3.12,0,0,0-.52-1.88l-.28-.36.46.07a5.23,5.23,0,0,0,2,0M48,110.56h4.48v5H48Zm6.25,14.68h-7v-4.06h0v-.5h0v-.35h5.3a1.63,1.63,0,0,1,1.68,1.55,2.26,2.26,0,0,1,0,.26Zm.64-5.45H47.28v-1.06l0,0a9.72,9.72,0,0,0,1-2.44l0-.13h3.83l0,.11a6.5,6.5,0,0,0,2.26,2.94l1,.64ZM39.48,109.64a16.18,16.18,0,0,0-3.75-2.29c-.07.1-.14.18-.21.27a7,7,0,0,1,1.8,3.39c.1,1.4.61,2.08,1.56,2.08s1.46-.67,1.59-1.86a2.24,2.24,0,0,0-1-1.6" />
          <path d="M7.85,114.26H4.36l-.74,0a7.39,7.39,0,0,1-1.49,3.58A11.64,11.64,0,0,1,0,119.69l.06.11a19,19,0,0,0,5.1-2.19,6.22,6.22,0,0,0,2.7-3.35M20,120.42h-5.7v-7.09H23.9l-.43-.55h-9.2v-2.36h.18a64.68,64.68,0,0,0,8.44-1l-3.4-1.94A142.21,142.21,0,0,1,.77,110.27c0,.08,0,.21,0,.27.94.12,9.4-.08,9.4-.08v2.32H.27l.43.55h9.49v7.08H5.75v-.63H1.69v10.86h21v-7.59c0-1.77-.89-2.63-2.72-2.63m-1.46,9.67H5.76v-4.3H18.51Zm0-4.85H5.76v-2.89h0V121H17.2a1.17,1.17,0,0,1,1.31,1,.94.94,0,0,1,0,.29ZM21.66,116a26.29,26.29,0,0,0-5-1.72l-.28.27a12.44,12.44,0,0,1,3.79,3.82,2,2,0,0,0,1.59,1.07,1.54,1.54,0,0,0,1.69-1.36.17.17,0,0,1,0-.07c0-.68-.6-1.36-1.76-2" />
          <path d="M127.46,49.74c-6.68,2.26-14.88,5.5-18.91,10.58a11.15,11.15,0,0,0-2.34,4.84,4.71,4.71,0,0,0-.06,1.3c0-.25-.05-.5-.06-.76V54.59c0-9.09,11.92-14.05,21.37-17.25s20.16-6.92,21.42-12.62c0-.13.05-.19.08-.19s0,.13,0,.2V36c0,6-10.08,9.86-21.54,13.73" />
          <path d="M127.46,25.2c-6.68,2.26-14.88,5.5-18.91,10.58a11.1,11.1,0,0,0-2.34,4.84,4.71,4.71,0,0,0-.06,1.3c0-.25-.05-.51-.06-.76V30.05c0-9.09,11.92-14,21.37-17.25S147.62,5.89,148.88.19c0-.14.05-.19.08-.19s0,.12,0,.19V11.48c0,6-10.08,9.85-21.54,13.72" />
          <path d="M75.18,49.74c6.68,2.26,14.88,5.5,18.9,10.58a11,11,0,0,1,2.34,4.84,4.71,4.71,0,0,1,.06,1.3,6.31,6.31,0,0,0,.07-.76V54.59c0-9.09-11.92-14.05-21.37-17.25S55,30.42,53.76,24.72c0-.13,0-.19-.09-.19s0,.13,0,.2V36c0,6,10.09,9.86,21.55,13.73" />
          <path d="M75.18,25.2c6.68,2.26,14.88,5.5,18.9,10.58a11,11,0,0,1,2.34,4.84,4.71,4.71,0,0,1,.06,1.3,6.51,6.51,0,0,0,.07-.76V30.05c0-9.09-11.92-14-21.37-17.25S55,5.89,53.76.19c0-.14,0-.19-.08-.19s0,.12,0,.19V11.48c0,6,10.08,9.85,21.54,13.72" />
        </g>
        <g fill="#e50019">
          <path d="M127.46,70.16c-6.68-2.26-14.88-5.5-18.91-10.58a11.15,11.15,0,0,1-2.34-4.84,4.73,4.73,0,0,1-.06-1.3c0,.25-.05.5-.06.76V65.31c0,9.09,11.92,14,21.37,17.25s20.16,6.91,21.42,12.62c0,.13.05.18.08.18s0-.12,0-.19V83.89c0-6-10.08-9.86-21.54-13.73" />
          <path d="M127.46,45.62c-6.68-2.25-14.88-5.5-18.91-10.58a11.1,11.1,0,0,1-2.34-4.84,4.71,4.71,0,0,1-.06-1.3c0,.25-.05.51-.06.76V40.77c0,9.09,11.92,14,21.37,17.25s20.16,6.92,21.42,12.62c0,.13.05.19.08.19s0-.13,0-.2V59.35c0-6-10.08-9.86-21.54-13.73" />
          <path d="M75.18,70.16c6.68-2.26,14.88-5.5,18.9-10.58a11,11,0,0,0,2.34-4.84,4.73,4.73,0,0,0,.06-1.3,6.31,6.31,0,0,1,.07.76V65.31c0,9.09-11.92,14-21.37,17.25S55,89.47,53.76,95.18c0,.13,0,.18-.09.18s0-.12,0-.19V83.89c0-6,10.09-9.86,21.55-13.73" />
          <path d="M75.18,45.62c6.68-2.25,14.88-5.5,18.9-10.58a11,11,0,0,0,2.34-4.84,4.71,4.71,0,0,0,.06-1.3,6.51,6.51,0,0,1,.07.76V40.77c0,9.09-11.92,14-21.37,17.25S55,64.94,53.76,70.64c0,.13,0,.19-.09.19s0-.13,0-.2V59.35c0-6,10.09-9.86,21.55-13.73" />
        </g>
      </svg>
    </Link>
  );
}
